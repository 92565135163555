.grid-container {
  flex: 1;
}

.grid-item {
  border: black 1px dashed;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item-wrapper {
  display: flex;
}

.empty-grid-item {
  border-radius: '20px';
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}
.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
