.accordion-header {
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.accordion-card {
  padding: 5px;
}
