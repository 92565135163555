.header {
  padding: 10px;
}

.segment-button {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.filter {
  margin: 20px;
}

.search {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 7 px;
  padding-inline-start: 7 px;
  -webkit-padding-end: 7 px;
  padding-inline-end: 7 px;
  padding-top: 3 px;
  padding-bottom: 3 px;
  --background: var(--ion-background-color, #fff);
  --border-radius: 2px;
  --box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --cancel-button-color: var(--ion-color-step-900, #1a1a1a);
  --clear-button-color: initial;
  --color: var(--ion-color-step-850, #262626);
  --icon-color: var(--ion-color-step-600, #666666);
  background: inherit;
}

ul {
  list-style-type: none;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: var(--ion-color-warning-tint);
}

[class^='ais-'] {
  font-size: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.card {
  cursor: pointer;
  max-width: 400px;
}

.card:hover {
  background-color: rgba(var(--ion-color-secondary-rgb), 0.05);
}
.item-rating {
  justify-content: start !important;
}

.sound-clips-grid {
  flex: 1;
}
