.sound-rating-container {
  display: flex;
  flex-direction: column;
}

.sound-rating-icon {
  color: var(--rating-icon-color);
}

.sound-rating-group {
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.and-up {
  margin-left: 5px;
  font-size: small;
}

.active {
  background-color: var(--ion-color-tertiary-tint);
  color: var(--ion-color-tertiary-contrast);
}

.ratings-label {
  font-weight: bolder;
}
