.rate-text {
  text-align: center;
}

.rating-container {
  display: flex;
  justify-content: center;
}

.rating-modal {
  --height: 300px;
}
